/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVnaLabel = /* GraphQL */ `
  mutation CreateVnaLabel(
    $input: CreateVNALabelInput!
    $condition: ModelVNALabelConditionInput
  ) {
    createVNALabel(input: $input, condition: $condition) {
      id
      name
      clients {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVnaLabel = /* GraphQL */ `
  mutation UpdateVnaLabel(
    $input: UpdateVNALabelInput!
    $condition: ModelVNALabelConditionInput
  ) {
    updateVNALabel(input: $input, condition: $condition) {
      id
      name
      clients {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVnaLabel = /* GraphQL */ `
  mutation DeleteVnaLabel(
    $input: DeleteVNALabelInput!
    $condition: ModelVNALabelConditionInput
  ) {
    deleteVNALabel(input: $input, condition: $condition) {
      id
      name
      clients {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVnaClient = /* GraphQL */ `
  mutation CreateVnaClient(
    $input: CreateVNAClientInput!
    $condition: ModelVNAClientConditionInput
  ) {
    createVNAClient(input: $input, condition: $condition) {
      id
      name
      label {
        id
        name
        clients {
          nextToken
        }
        createdAt
        updatedAt
      }
      campaigns {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVnaClient = /* GraphQL */ `
  mutation UpdateVnaClient(
    $input: UpdateVNAClientInput!
    $condition: ModelVNAClientConditionInput
  ) {
    updateVNAClient(input: $input, condition: $condition) {
      id
      name
      label {
        id
        name
        clients {
          nextToken
        }
        createdAt
        updatedAt
      }
      campaigns {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVnaClient = /* GraphQL */ `
  mutation DeleteVnaClient(
    $input: DeleteVNAClientInput!
    $condition: ModelVNAClientConditionInput
  ) {
    deleteVNAClient(input: $input, condition: $condition) {
      id
      name
      label {
        id
        name
        clients {
          nextToken
        }
        createdAt
        updatedAt
      }
      campaigns {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVnaCampaigns = /* GraphQL */ `
  mutation CreateVnaCampaigns(
    $input: CreateVNACampaignsInput!
    $condition: ModelVNACampaignsConditionInput
  ) {
    createVNACampaigns(input: $input, condition: $condition) {
      id
      name
      client {
        id
        name
        label {
          id
          name
          createdAt
          updatedAt
        }
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          active
          name
          frequency
          dayhourindicator
          segments
          uriimage
          urlredirect
          text
          label
          urlsenttoftp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVnaCampaigns = /* GraphQL */ `
  mutation UpdateVnaCampaigns(
    $input: UpdateVNACampaignsInput!
    $condition: ModelVNACampaignsConditionInput
  ) {
    updateVNACampaigns(input: $input, condition: $condition) {
      id
      name
      client {
        id
        name
        label {
          id
          name
          createdAt
          updatedAt
        }
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          active
          name
          frequency
          dayhourindicator
          segments
          uriimage
          urlredirect
          text
          label
          urlsenttoftp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVnaCampaigns = /* GraphQL */ `
  mutation DeleteVnaCampaigns(
    $input: DeleteVNACampaignsInput!
    $condition: ModelVNACampaignsConditionInput
  ) {
    deleteVNACampaigns(input: $input, condition: $condition) {
      id
      name
      client {
        id
        name
        label {
          id
          name
          createdAt
          updatedAt
        }
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          active
          name
          frequency
          dayhourindicator
          segments
          uriimage
          urlredirect
          text
          label
          urlsenttoftp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVnaOrders = /* GraphQL */ `
  mutation CreateVnaOrders(
    $input: CreateVNAOrdersInput!
    $condition: ModelVNAOrdersConditionInput
  ) {
    createVNAOrders(input: $input, condition: $condition) {
      id
      active
      name
      campaigns {
        id
        name
        client {
          id
          name
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      frequency
      dayhourindicator
      segments
      uriimage
      billing
      SMS
      smsoptions
      urlredirect
      text
      label
      urlsenttoftp
      createdAt
      updatedAt
    }
  }
`;
export const updateVnaOrders = /* GraphQL */ `
  mutation UpdateVnaOrders(
    $input: UpdateVNAOrdersInput!
    $condition: ModelVNAOrdersConditionInput
  ) {
    updateVNAOrders(input: $input, condition: $condition) {
      id
      active
      name
      billing
      campaigns {
        id
        name
        client {
          id
          name
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      frequency
      dayhourindicator
      segments
      uriimage
      SMS
      smsoptions
      urlredirect
      text
      label
      urlsenttoftp
      createdAt
      updatedAt
    }
  }
`;
export const deleteVnaOrders = /* GraphQL */ `
  mutation DeleteVnaOrders(
    $input: DeleteVNAOrdersInput!
    $condition: ModelVNAOrdersConditionInput
  ) {
    deleteVNAOrders(input: $input, condition: $condition) {
      id
      active
      name
      campaigns {
        id
        name
        client {
          id
          name
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      frequency
      SMS
      smsoptions
      dayhourindicator
      segments
      uriimage
      urlredirect
      text
      label
      urlsenttoftp
      createdAt
      updatedAt
    }
  }
`;
export const createVnaUsers = /* GraphQL */ `
  mutation CreateVnaUsers(
    $input: CreateVNAUsersInput!
    $condition: ModelVNAUsersConditionInput
  ) {
    createVNAUsers(input: $input, condition: $condition) {
      id
      name
      email
      labels
      rights
      createdAt
      updatedAt
    }
  }
`;
export const updateVnaUsers = /* GraphQL */ `
  mutation UpdateVnaUsers(
    $input: UpdateVNAUsersInput!
    $condition: ModelVNAUsersConditionInput
  ) {
    updateVNAUsers(input: $input, condition: $condition) {
      id
      name
      email
      labels
      rights
      createdAt
      updatedAt
    }
  }
`;
export const deleteVnaUsers = /* GraphQL */ `
  mutation DeleteVnaUsers(
    $input: DeleteVNAUsersInput!
    $condition: ModelVNAUsersConditionInput
  ) {
    deleteVNAUsers(input: $input, condition: $condition) {
      id
      name
      email
      labels
      rights
      createdAt
      updatedAt
    }
  }
`;
export const createVnaCommands = /* GraphQL */ `
  mutation CreateVnaCommands(
    $input: CreateVNACommandsInput!
    $condition: ModelVNACommandsConditionInput
  ) {
    createVNACommands(input: $input, condition: $condition) {
      id
      commandpostdate
      done
      command
      createdAt
      updatedAt
    }
  }
`;
export const updateVnaCommands = /* GraphQL */ `
  mutation UpdateVnaCommands(
    $input: UpdateVNACommandsInput!
    $condition: ModelVNACommandsConditionInput
  ) {
    updateVNACommands(input: $input, condition: $condition) {
      id
      commandpostdate
      done
      command
      createdAt
      updatedAt
    }
  }
`;
export const deleteVnaCommands = /* GraphQL */ `
  mutation DeleteVnaCommands(
    $input: DeleteVNACommandsInput!
    $condition: ModelVNACommandsConditionInput
  ) {
    deleteVNACommands(input: $input, condition: $condition) {
      id
      commandpostdate
      done
      command
      createdAt
      updatedAt
    }
  }
`;
