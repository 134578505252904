import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Utils from "../utils/utils";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import GetAppIcon from '@material-ui/icons/GetApp';
class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: this.props.authState,
      users: [],
      labels: [],
      openlabels: false,
      openlabelsUser: null,
      triggerorder:"",
      newLabelName: "",
      shortcodes: [],
      code: "",
      redirecturl: "",
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.authState.state !== this.props.authState.state) {
      this.setState({ authState: this.props.authState });

      this.getClients();
      this.getShortCodes();
    }
  }

  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  async runOrder2segment() {
    let s = await fetch(
      "https://luqkgkokq4.execute-api.eu-west-1.amazonaws.com/production/triggersetorder2segment",
      {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          authtoken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
      }
    );
    let t = await s.text();
    this.setState({ triggerorder: t });
  }

  async getShortCodes() {
    let s = await fetch(
      "https://l8rqkk68xl.execute-api.af-south-1.amazonaws.com/production/getcodes?func=ddb",
      {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
      }
    );
    let t = await s.json();
    this.setState({ shortcodes: t.Items });
  }

  async putShortCodes(code, url) {
    console.log({code,url})
    let s = await fetch(
      "https://l8rqkk68xl.execute-api.af-south-1.amazonaws.com/production/getcodes?func=put&code=" +
        code.toLowerCase().replace(/\s/g, '').trim()  +
        "&url=" +
        url.replace(/&/g,"QQANDQQ").replace(/=/g,"QQEQQQ"),
      {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
      }
    );
    await s.json();
    this.setState({ code: "", redirecturl: "" });
    await this.getShortCodes();
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.state.authState.state !== "signedIn")
        this.props.history.push("/");
      else {
        this.getClients();
        this.getShortCodes();
      }
    }, 1000);
  }
  async getlog(code) {

    this.doSnackBar("Getting log. Takes a minute.");
    let s = await fetch(
      "https://l8rqkk68xl.execute-api.af-south-1.amazonaws.com/production/getcodes?func=getlog&code=" +
        code.toLowerCase(),
      {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
      }
    );
    let t = await s.json();
    t.forEach((z) => (z.date = z.date.value));
    // console.log(t);
    let text = "";
    text += "date,agent,shortcode\n";
    t.forEach((z) => {
      text += z.date + "," + z.agent + "," + z.shortcode + "\n";
    });
    const link = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    link.href = URL.createObjectURL(file);
    // link.href = `log.csv`;
    link.download = code+".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async removeLabelFromUser(user, selected) {
    this.doSnackBar("Removing");
    let data = Object.assign(
      {},
      this.state.users.find((z) => z.id === user)
    );
    delete data.updatedAt;
    delete data.createdAt;
    console.log({ selected });
    data.labels = data.labels.filter((z) => z !== selected).join(",");

    await Utils.genericMutation("updateVnaUsers", data);
    // await Utils.genericMutation("createVnaUsers", data);
    // this.doSnackBar("Added user");
    this.getClients();
  }
  async addLabelFromUser(user, selected) {
    this.doSnackBar("Adding");
    let data = Object.assign(
      {},
      this.state.users.find((z) => z.id === user)
    );
    delete data.updatedAt;
    delete data.createdAt;
    // console.log({ selected });
    data.labels.push(selected.id);
    data.labels = [...new Set(data.labels)];
    data.labels = data.labels.filter((z) => z !== "");
    if (data.labels.filter((z) => z === "*").length > 0) data.labels = ["*"];
    data.labels = data.labels.join(",");

    this.setState({ openlabels: false });
    await Utils.genericMutation("updateVnaUsers", data);
    // await Utils.genericMutation("createVnaUsers", data);
    // this.doSnackBar("Added user");
    this.getClients();
  }
  async getClients() {
    this.doSnackBar("Collecting data");
    let users = await Utils.genericList("listVnaUserss", { limit: 100 });

    users.data.listVNAUserss.items.forEach((item, i) => {
      item.labels = item.labels.split(",");
      item.labels = item.labels.filter((z) => z !== "");
    });
    let auth = users.data.listVNAUserss.items.find(
      (z) => z.id === this.state.authState.usr
    );
    if (auth && auth.labels.find((z) => z === "*")) {
    } else {
      this.props.history.push("/");
    }

    // console.log({ users });
    if (users) this.setState({ users: users.data.listVNAUserss.items });
    let labels = await Utils.genericList("listVnaLabels", { limit: 100 });
    labels.data.listVNALabels.items.push({ id: "*", name: "ALL LABELS" });
    if (labels) this.setState({ labels: labels.data.listVNALabels.items });
  }
  async addClient() {
    let data = {
      id: "7e69eba6-fa4d-4f88-8db3-e91026e27e02",
      name: "Sean",
      email: "sean@vnamobi.com",
      labels: "6173b6a8-078a-46ce-b85d-a233d5e0418d,",
      rights: "*",
    };
    await Utils.genericMutation("createVnaUsers", data);
    this.doSnackBar("Added user");
    this.getClients();
    // this.setState({ clients });
  }
  async addLabel(name) {
    let data = {
      name,
    };
    await Utils.genericMutation("createVnaLabel", data);
    this.doSnackBar("Added label");
    this.getClients();
    // this.setState({ clients });
  }
  render() {
    // console.log(this.state.authState)
    return (
      <div style={{ margin: 20, color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <Dialog
          onClose={() => this.setState({ openlabels: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openlabels}
        >
          <DialogTitle id="simple-dialog-title">Add label to user</DialogTitle>
          <List>
            {this.state.labels.map((email) => (
              <ListItem
                button
                onClick={() => {
                  this.addLabelFromUser(this.state.openlabelsUser, email);
                }}
                key={email.id}
              >
                <ListItemText primary={email.name} />
              </ListItem>
            ))}
          </List>
        </Dialog>
        <Paper style={{ padding: 10, background: "#eee" }}>
          <h1>Shortcodes</h1>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Code</b>
                </TableCell>
                <TableCell>
                  <b>Actual URL</b>
                </TableCell>
                <TableCell>
                  <b>Download logs</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.shortcodes.map((z, idx) => (
                <TableRow key={idx}>
                  <TableCell>{"https://v-n-a.link/" + z.code}</TableCell>
                  <TableCell>{z.redirect}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.getlog(z.code);
                      }}
                    >
                      <GetAppIcon/> Download CSV
                    </Button>
                  </TableCell>

                </TableRow>
              ))}
              <TableRow style={{ marginRight: 10 , alignItems:"center", background:"#aaa"}}>
                <TableCell>
                  <div
                    style={{ display: "flex" , alignItems:"center" , flexDirection:"row"}}
                    >
                  https://v-n-a.link/
                  <TextField
                  style={{ marginLeft: 10 , alignSelf:"center",width:"60%"}}
                  value={this.state.code}
                  onChange={(change) => {


                    this.setState({ code: change.target.value.trim().replace("https://v-n-a.link/","").trim() });
                  }}
                  id="outlined-basic"
                  label="Short code (without v-n-a...)"
                  variant="outlined"
                /></div></TableCell>
                <TableCell>
                  <TextField
                    style={{ marginRight: 10 , width:"100%"}}
                    value={this.state.redirecturl}
                    onChange={(change) => {
                      this.setState({ redirecturl: change.target.value });
                    }}
                    id="outlined-basic"
                    label="Redirect URL"
                    variant="outlined"
                  /></TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.putShortCodes(this.state.code, this.state.redirecturl);
                    }}
                  >
                    Create new code
                  </Button>
                </TableCell>

              </TableRow>

            </TableBody>
          </Table>
          <div>



            <br />
            NB: You can distribute a short URL (eg: https://v-n-a.link/TEst1)
            with capital letters, the backend will convert it to lower case
          </div>
        </Paper>
        <Paper style={{ padding: 10, marginTop: 10, background: "#eee" }}>
          <h1>Users</h1>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Email</b>
                </TableCell>
                <TableCell>
                  <b>Labels (1 Max)</b>
                </TableCell>
                <TableCell>
                  <b>Rights</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.users.map((z, idx) => (
                <TableRow key={idx}>
                  <TableCell>{z.name}</TableCell>
                  <TableCell>{z.email}</TableCell>
                  <TableCell>
                    {z.labels
                      .filter((z) => z !== "")
                      .map((a, aidx) => (
                        <Chip
                          key={aidx}
                          label={
                            this.state.labels.filter((z) => z.id === a).length >
                            0
                              ? this.state.labels.filter((z) => z.id === a)[0]
                                  .name
                              : "DELETED"
                          }
                          onDelete={() => {
                            this.removeLabelFromUser(z.id, a);
                          }}
                        />
                      ))}
                    <Button
                      disabled={z.labels.length > 0 ? true : false}
                      onClick={() =>
                        this.setState({
                          openlabels: true,
                          openlabelsUser: z.id,
                        })
                      }
                    >
                      <AddCircleOutlineIcon />
                    </Button>
                  </TableCell>
                  <TableCell>{z.rights}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Paper style={{ padding: 10, marginTop: 10, background: "#eee" }}>
          <h1>Labels</h1>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Labels</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.labels.map((z, idx) => (
                <TableRow key={idx}>
                  <TableCell>{z.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <h1>Add Label</h1>
          <div style={{ alignItems: "center", display: "flex" }}>
            <TextField
              style={{ marginRight: 10 }}
              value={this.state.newLabelName}
              onChange={(change) => {
                this.setState({ newLabelName: change.target.value });
              }}
              id="outlined-basic"
              label="Label name"
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.addLabel(this.state.newLabelName);
                this.setState({ newLabelName: "" });
              }}
            >
              Add new label
            </Button>
          </div>
        </Paper>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.props.history.push("/servercommands");
          }}
        >
          Secret server command menu
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
          this.runOrder2segment()
          }}
        >
          Trigger order2segment
        </Button>
        <br/>
        {this.state.triggerorder}
        <br/>
      </div>
    );
  }
}
export default withRouter(ClientPage);
