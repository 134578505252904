import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Utils from "../utils/utils.js";
import provcodes from "./provREV.json";
import municodes from "./muniREV.json";
import citycodes from "./cityREV.json";
import subscodes from "./subREV.json";

function Box({ onChange, gotMaster, values }) {
  const [segmentMaster, setSegmentMaster] = useState([]);
  const [selected, setSelected] = useState([]);
  const [initvalues, setInitvalues] = useState(false);
  const [arpucheck, setArpucheck] = useState({
    A0: true,
    A1: true,
    A2: true,
    A3: true,
    A4: true,
    A5: true,
    A6: true,
  });
  const arpus = [
    { id: "A0", name: "0 or less" },
    { id: "A1", name: "R1-R75" },
    { id: "A2", name: "R76-150" },
    { id: "A3", name: "R151-R200" },
    { id: "A4", name: "R201-R300" },
    { id: "A5", name: "R301-R500" },
    { id: "A6", name: "R501 or more" },
  ];
  const mnos = [
    { id: "NC", name: "Cell C" },
    { id: "NM", name: "MTN" },
    // { id: "NN", name: "MTN:nonsoc" },
    { id: "NZ", name: "Test Phone" },
  ];
  const [mnocheck, setMNOcheck] = useState({
    NM: false,
    NN: false,
    NC: false,
    NZ: false,
  });

  async function getmaster() {
    let segs = await fetch(
      "https://ba4lokxtk4aj2e4r5kll75c3ka0lfcvc.lambda-url.af-south-1.on.aws/"
    );
    segs = await segs.json();
    // console.log({ segs });
    function reformat(kv) {
      let res = { n: kv.n };
      kv.ids.split(",").forEach((z) => {
        res[z.substr(0, 1)] = z;
      });
      if (!res.M) res.M = "M0";
      if (!res.S) res.S = "S0";
      return res;
    }

    let keys = Object.keys(segs)

      .map((z) => {
        return { ids: z, n: parseInt(segs[z]) };
      })
      .map(reformat);
    gotMaster(keys);
    // onChange({ mnocheck, arpucheck });
    // console.log({countstotal:keys.reduce((a,b)=>a=a+b.n,0)})
    function maketree(a) {
      let geo = [];

      keys.forEach((a) => {
        if (a.N === undefined) a.N = "NC";
        if (a.A === undefined) a.A = "A0";
        if (a.P === undefined) a.P = "P0";
        if (a.M === undefined) a.M = "M0";
        if (a.C === undefined) a.C = "C0";
        if (a.S === undefined) a.S = "S0";
        let foundp = geo.find((z) => z.id === a.P);
        if (foundp) {
          let foundm = foundp.ch.find((z) => z.id === a.M);
          if (foundm) {
            let foundc = foundm.ch.find((z) => z.id === a.C);
            if (foundc) {
              let founds = foundc.ch.find((z) => z.id === a.S);
              if (founds) founds.ch.push({ N: a.N, A: a.A, n: a.n });
              else if (!founds)
                foundc.ch.push({
                  id: a.S,
                  ky: Utils.create_UUID(),
                  ch: [{ N: a.N, A: a.A, n: a.n, ky: Utils.create_UUID() }],
                });
            } else
              foundm.ch.push({
                id: a.C,
                ky: Utils.create_UUID(),
                ch: [
                  {
                    id: a.S,
                    ky: Utils.create_UUID(),
                    ch: [{ N: a.N, A: a.A, n: a.n, ky: Utils.create_UUID() }],
                  },
                ],
              });
          } else
            foundp.ch.push({
              id: a.M,

              ky: Utils.create_UUID(),
              ch: [
                {
                  id: a.C,
                  ky: Utils.create_UUID(),
                  ch: [
                    {
                      id: a.S,
                      ky: Utils.create_UUID(),
                      ch: [{ N: a.N, A: a.A, n: a.n, ky: Utils.create_UUID() }],
                    },
                  ],
                },
              ],
            });
        } else
          geo.push({
            id: a.P,
            ky: Utils.create_UUID(),
            ch: [
              {
                id: a.M,
                ky: Utils.create_UUID(),
                ch: [
                  {
                    id: a.C,
                    ky: Utils.create_UUID(),
                    ch: [
                      {
                        id: a.S,
                        ky: Utils.create_UUID(),
                        ch: [
                          { N: a.N, A: a.A, n: a.n, ky: Utils.create_UUID() },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          });
      });

      return [{ id: "All regions", ch: geo, ky: "asd" }];
    }
    let tre = maketree(keys);
    // console.log({ tre, keys });
    setSegmentMaster(tre);
    // setSegmentCounts(keys);
    setInitalValues(tre);
    return null;
  }

  function setInitalValues(sm2) {
    setInitvalues(true);
    // console.log({ values });
    // if (values)

    let arr = values.map((z) => z.split(","));

    let n = [
      ...new Set(
        arr.map((z) => {
          return z.find((q) => q.substr(0, 1) === "N");
        })
      ),
    ];
    // console.log(n);
    let mno = mnocheck;
    n.forEach((item, i) => {
      mno[item] = true;
    });
    setMNOcheck(mno);

    let arp = [
      ...new Set(
        arr.map((z) => {
          return z.find((q) => q.substr(0, 1) === "A");
        })
      ),
    ];
    arp = arp.filter((z) => z !== undefined);
    // console.log({ arp });
    let arpu = arpucheck;
    if (arp.length > 0) {
      arpu = {
        A0: false,
        A1: false,
        A2: false,
        A3: false,
        A4: false,
        A5: false,
        A6: false,
      };
    }
    arp.forEach((item, i) => {
      arpu[item] = true;
    });
    setArpucheck(arpu);

    arr = arr.map((item, i) => {
      return JSON.stringify(
        item
          .filter((z) => z.substr(0, 1) !== "A")
          .filter((z) => z.substr(0, 1) !== "N")
      );
    });
    arr = [...new Set(arr)].map((z) => JSON.parse(z));
    let arrnew = [];
    // console.log({sm2});
    let sm = Object.assign([], sm2);
    arr = arr.filter((z) => z.length !== 0);
    if (arr.length === 0) sm[0].selected = true;
    else {
      arr.forEach((item, i) => {
        if (item.length === 1) arrnew.push({ P: item[0] });
        if (item.length === 2) arrnew.push({ P: item[0], M: item[1] });
        if (item.length === 3)
          arrnew.push({ P: item[0], M: item[1], C: item[2] });
        if (item.length === 4)
          arrnew.push({ P: item[0], M: item[1], C: item[2], S: item[3] });
        sm.forEach((a, i) => {
          a.ch.forEach((p, i) => {
            if (p.id === item[0] && item.length === 1) p.selected = true;
            p.ch.forEach((m, i) => {
              if (p.id === item[0] && m.id === item[1] && item.length === 2) {
                // console.log(m.id, item[1]);
                m.selected = true;
              }
              m.ch.forEach((c, i) => {
                if (
                  p.id === item[0] &&
                  m.id === item[1] &&
                  c.id === item[2] &&
                  item.length === 3
                )
                  c.selected = true;
                c.ch.forEach((s, i) => {
                  if (
                    p.id === item[0] &&
                    m.id === item[1] &&
                    c.id === item[2] &&
                    s.id === item[3]
                  )
                    s.selected = true;
                });
              });
            });
          });
        });
      });
    }
    // console.log({ arrnew });
    setSegmentMaster(sm);
    setSelected(arrnew);
  }
  function sendupdateup() {
    let final = [];
    let mnos = [];

    if (mnocheck.NM) mnos.push("NM");
    if (mnocheck.NN) mnos.push("NN");
    if (mnocheck.NC) mnos.push("NC");
    if (mnocheck.NZ) mnos.push("NZ");
    if ([...new Set(Object.values(arpucheck))].length !== 1) {
      let ap = Object.keys(arpucheck);
      ap.forEach((item, i) => {
        if (arpucheck[item] === true) {
          mnos.forEach((itemm, i) => {
            final.push(itemm + "," + item);
          });
        }
      });
    } else final = mnos;
    if (selected.length === 0) {
      // final = mnos;
      console.log("no selected");
    } else {
      if (selected === "all") {
        console.log("everything");
      } else {
        console.log("only some");
        let loop = Object.assign([], final);
        let newf = [];
        loop.forEach((iteml, i) => {
          selected.forEach((items, i) => {
            newf.push(iteml + "," + Object.values(items).join(","));
          });
        });
        final = newf;
      }
    }

    // console.log({ selected, arpucheck, mnocheck, soccheck, soc, final });
    onChange(final);
  }

  useEffect(() => {
    // console.log({ values });
    getmaster();
  }, []);

  // useEffect(() => {
  // console.log({ values ,segmentMaster});
  //   if (!initvalues && segmentMaster.length !== 0) setInitalValues();
  // }, [values, segmentMaster]);
  // console.log({ky})

  useEffect(() => {
    // console.log({selected, arpucheck, mnocheck})
    sendupdateup();
  }, [selected, arpucheck, mnocheck]);

  return (
    <div style={{ borderTop: "3px solid #000", marginBottom: 30 }}>
      <div>
        <h4>Total selected: </h4>
        <h4>Networks</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {mnos.map((z, i) => (
            <div
              key={z.id}
              style={{
                border: "1px solid #bbb",
                margin: 2,
                borderRadius: 3,
                padding: 5,
                paddingRight: 10,
              }}
            >
              <Checkbox
                checked={mnocheck[z.id]}
                onClick={(ch) => {
                  let tmp = Object.assign({}, mnocheck);
                  tmp[z.id] = ch.target.checked;
                  setMNOcheck(tmp);
                  // onChange({ selected, mnocheck: tmp, arpucheck });
                }}
              />
              {z.name}
            </div>
          ))}
        </div>

        <div>
          <h4>Monthly Average Revenue Per User</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {arpus.map((z, i) => (
              <div
                key={z.id}
                style={{
                  border: "1px solid #bbb",
                  margin: 2,
                  borderRadius: 3,
                  padding: 5,
                  paddingRight: 10,
                }}
              >
                <Checkbox
                  key={z.id}
                  checked={arpucheck[z.id]}
                  onClick={(ch) => {
                    let tmp = Object.assign({}, arpucheck);
                    tmp[z.id] = ch.target.checked;
                    setArpucheck(tmp);

                    //       onChange({ selected, mnocheck, arpucheck: tmp });
                  }}
                />
                {z.name}
              </div>
            ))}
          </div>
        </div>
        <h4>Geographic targeting</h4>
        <div>
          <Section
            data={segmentMaster}
            onChange={(ss) => {
              // console.log({ss})
            }}
            onParentChange={(ss) => {
              // console.log(ss);
              function calcdown(inp) {
                let t = 0;
                if (inp.ch) {
                  inp.ch.forEach((z) => {
                    t += calcdown(z);
                  });
                } else {
                  if (mnocheck[inp.N] && arpucheck[inp.A]) t += inp.n;
                }
                return t;
              }
              if (ss.selected) {
                // onChange({ mnocheck, arpucheck, selected: "all" });
                setSelected("all");
              } else {
                let f = [];

                if (ss.selected) f.push({ all: true });
                ss.ch.forEach((zp) => {
                  if (zp.selected) f.push({ P: zp.id });
                  zp.ch.forEach((zm) => {
                    if (zm.selected) f.push({ P: zp.id, M: zm.id });
                    zm.ch.forEach((zc) => {
                      if (zc.selected) f.push({ P: zp.id, M: zm.id, C: zc.id });
                      zc.ch.forEach((zs) => {
                        if (zs.selected)
                          f.push({ P: zp.id, M: zm.id, C: zc.id, S: zs.id });
                      });
                    });
                  });
                });

                let pureP = [];
                f.forEach((p) => {
                  if (p.P !== undefined && p.M === undefined) pureP.push(p);
                });

                f = f.filter((p) => {
                  if (p.P !== undefined && p.M !== undefined) {
                    let found = pureP.find((a) => a.P === p.P);
                    if (found) return false;
                    else return true;
                  } else return true;
                });

                let pureM = [];
                f.forEach((p) => {
                  if (p.M !== undefined && p.C === undefined) pureM.push(p);
                });

                f = f.filter((p) => {
                  if (p.M !== undefined && p.C !== undefined) {
                    let found = pureM.find((a) => a.M === p.M);
                    if (found) return false;
                    else return true;
                  } else return true;
                });

                let pureC = [];
                f.forEach((p) => {
                  if (p.C !== undefined && p.S === undefined) pureC.push(p);
                });

                f = f.filter((p) => {
                  if (p.C !== undefined && p.S !== undefined) {
                    let found = pureC.find((a) => a.C === p.C);
                    if (found) return false;
                    else return true;
                  } else return true;
                });

                // console.log({ f });
                setSelected(f);
                // onChange({ selected: f, mnocheck, arpucheck });
              }
            }}
            toggles={{ arpucheck, mnocheck }}
          />
        </div>
      </div>
    </div>
  );
}
export default Box;
function prettynumber(number) {
  if (number > 1000000) return (number / 1000000).toFixed(1) + "m";
  else if (number > 1000) return (number / 1000).toFixed(1) + "k";
  else return number + "";
}

function getcolour(itm) {
  if (itm.toggle && itm.id.substr(0, 1) === "P") return "#ff1744";
  if (itm.toggle && itm.id.substr(0, 1) === "M") return "#2979ff";
  if (itm.toggle && itm.id.substr(0, 1) === "C") return "#1de9b6";
  if (itm.toggle && itm.id.substr(0, 1) === "S") return "#ffea00";
  if (!itm.toggle && itm.id.substr(0, 1) === "P") return "#d555f9";
  if (!itm.toggle && itm.id.substr(0, 1) === "M") return "#00e5ff";
  if (!itm.toggle && itm.id.substr(0, 1) === "C") return "#76ff03";
  if (!itm.toggle && itm.id.substr(0, 1) === "S") return "#ff9100";
  return "#bbb";
}

function getname(inp) {
  function toProperCase(s) {
    if (s === undefined || s === null) return "Unknown";
    else
      return s
        .toLowerCase()
        .replace(/\b((m)(a?c))?(\w)/g, function ($1, $2, $3, $4, $5) {
          if ($2) {
            return $3.toUpperCase() + $4 + $5.toUpperCase();
          }
          return $1.toUpperCase();
        });
  }
  if (inp.substr(0, 1) === "P") return toProperCase(provcodes[inp]);
  else if (inp.substr(0, 1) === "M") return toProperCase(municodes[inp]);
  else if (inp.substr(0, 1) === "C") return toProperCase(citycodes[inp]);
  else if (inp.substr(0, 1) === "S") return toProperCase(subscodes[inp]);
  else return toProperCase(inp);
}

function Section({ data, ky, onChange, onParentChange, toggles }) {
  // const [subdata, setSubdata] = useState(null);
  function calcdown(inp) {
    let t = 0;
    if (inp.ch) {
      inp.ch.forEach((z) => {
        t += calcdown(z);
      });
    } else {
      if (toggles.mnocheck[inp.N] && toggles.arpucheck[inp.A]) t += inp.n;
    }
    return t;
  }

  const [indata, setIndata] = useState([]);
  useEffect(() => {
    // console.log({ data });
    setIndata(data);
  }, [data]);
  if (data === undefined || data.length === 0) return <div>Collecting...</div>;
  else
    return (
      <div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {indata.map((z, i) => (
            <div key={z.ky}>
              <Button
                style={{
                  background: getcolour(z),
                  margin: 2,
                  textTransform: "none",
                }}
                onClick={() => {
                  if (z.id.substr(0, 1) !== "S") {
                    let nd = Object.assign([], indata);
                    nd[i].toggle = !nd[i].toggle;
                    // console.log({ indata, i, z, nd });
                    setIndata(nd);
                    // setSubdata(z.ch);
                  }
                }}
              >
                <div>
                  {getname(z.id) + " " + prettynumber(calcdown(z))}
                  <Checkbox
                    checked={z.selected === true ? true : false}
                    onClick={(e) => {
                      let nd = Object.assign([], indata);
                      nd[i].selected = !nd[i].selected;
                      if (z.ky === "asd") {
                        // console.log({ z });
                        onParentChange(z);
                      }
                      // if (indata.ky === "asd")
                      //   console.log({ indata, i, z, nd });
                      setIndata(nd);
                      onChange(nd, ky);
                      e.stopPropagation();
                    }}
                  />
                </div>
              </Button>
            </div>
          ))}
          {indata
            .filter((z) => z.toggle === true)
            .map((z, i) => (
              <div key={z.ky}>
                <Section
                  toggles={toggles}
                  data={z.ch}
                  onChange={(ch, ky) => {
                    if (z.ky === "asd") {
                      onParentChange(z);
                    }
                    onChange(ch, ky);
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    );
}
